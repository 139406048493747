* {
  margin: 0;
  padding: 0;
}

.App {
  background-image: url(./img/emidia-background.png);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Make the container take up the full viewport height */
  overflow: hidden;
  /* Hide any potential scrollbars */
  min-height: 400px; /* Set a minimum height to maintain image size */

}

.emidia {
  max-width: 100%;
  /* Ensure the image doesn't exceed its container width */
  max-height: 100%;
  width: auto;
  /* Allow the image to adjust its width while maintaining aspect ratio */
  height: auto;
  /* Allow the image to adjust its height while maintaining aspect ratio */

}